import { IonContent, IonPage } from "@ionic/react";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import type { CreateManualTask } from "../../../../../../../../models/";
import { useAddTaskToWorkcardMutation } from "../../../../../../../../services/data/workcard.service";


// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const WorkcardTaskAddModal = ({ siteId, workcardId, onDismiss }: { siteId: number, workcardId: number, onDismiss: (data?: string | null | undefined | number, role?: string) => void; }) => {

    const { register,
        handleSubmit,
        formState: { isValid, errors }
    } = useForm<CreateManualTask>({
        defaultValues: {
            taskName: '',
            location: 0,
            customLocation: ''
        },
        mode: "onChange"
    });

    const [addTaskToWorkcard, { isLoading }] = useAddTaskToWorkcardMutation();

    const submit = (data: CreateManualTask) => {
        addTaskToWorkcard({ siteId, workcardId, payload: data })
            .unwrap()
            .then(() => {
                toast.success(t('FEATURES.WORKCARD.WORKCARD_GREETING.ADD_NEW_TASK.TASK_CREATED_SUCCESSFULLY'), {
                    duration: 2000,
                    position: 'top-center',
                });

                onDismiss('null', 'confirm');
            });
    };

    return (
        <IonPage>
            <IonContent className="ion-padding redbox-modal">
                <div className="flex justify-center mb-6">
                    <span className="dark:text-quartery-default"> {t('FEATURES.WORKCARD.WORKCARD_GREETING.ADD_NEW_TASK.MODAL_TITLE')}</span>
                </div>
                <form onSubmit={handleSubmit(submit)}>
                    <div className="px-0 py-4  lg:gap-4 lg:px-6">
                        <div className="leading-[1.1] text-[14px] text-black dark:text-quartery-default font-semibold">{t('FEATURES.WORKCARD.WORKCARD_GREETING.ADD_NEW_TASK.TASK_NAME')}</div>

                        <input
                            {...register("taskName", { required: true, maxLength: 50 })}
                            placeholder=""
                            maxLength={50}
                            data-question="taskName"
                            className={`focus:outline-none text-black bg-white border border-gray-400 dark:bg-dark-primary dark:text-gray-200 dark:border-dark-tertiary border-[2px] rounded-xl comment block w-full h-[50px] mt-2`}
                        ></input>
                        {errors.taskName?.type === 'required' && (
                            <div className="bottom-[-18px] text-[13px] text-red-600" role="alert">
                                <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                                <span className="font-semibold ">{t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}</span>
                            </div>)}

                    </div>
                    <div className="px-0 py-4  lg:gap-4 lg:px-6">
                        <div className="leading-[1.1] text-[14px] text-black dark:text-quartery-default font-semibold">{t('FEATURES.WORKCARD.WORKCARD_GREETING.ADD_NEW_TASK.TASK_LOCATION')}</div>

                        <input
                            {...register("customLocation", { required: true, maxLength: 30 })}
                            placeholder=""
                            maxLength={30}
                            data-question="customLocation"
                            className={`focus:outline-none text-black bg-white border border-gray-400 dark:bg-dark-primary dark:text-gray-200 dark:border-dark-tertiary border-[2px] rounded-xl comment block w-full h-[50px] mt-2`}
                        ></input>
                        {errors.customLocation?.type === 'required' && (
                            <>
                                <div className="bottom-[-18px] text-[13px] text-red-600" role="alert">
                                    <i className="fa-solid fa-exclamation-triangle mr-2"></i>
                                    <span className="font-semibold ">{t('FEATURES.WORKCARD.WORKCARD_GREETING.FIELD_REQUIRED')}</span>
                                </div>
                            </>)}
                    </div>
                    <footer className="absolute bottom-6 w-full left-1 px-4">
                        <div className="flex justify-between">
                            <button
                                onClick={() => onDismiss(null, 'cancel')}
                                type="button"
                                className="inline-flex items-center justify-center  rounded-xl px-4 py-1 w-[120px]   text-workcard-redbox-primary shadow-sm  sm:w-auto mr-3 mt-[-6px] border-[2px] border-workcard-redbox-primary border-solid text-[15px] uppercase font-bold"
                            >
                                {t('LABELS.CANCEL')}
                            </button>
                            <button
                                disabled={!isValid || isLoading}
                                value="submit"
                                type="submit"
                                className="inline-flex items-center justify-center rounded-xl border border-transparent px-4 py-1 w-[120px]  text-white shadow-sm  focus:outline-none  sm:w-auto mr-3 mt-[-6px] bg-gradient-to-r from-workcard-redbox-primary to-workcard-redbox-secondary text-[15px] uppercase font-bold"
                            >
                                {t('FEATURES.WORKCARD.WORKCARD_GREETING.ADD_NEW_TASK.CONFIRM_BUTTON')}
                                <i className="fa-solid fa-circle-right ml-1.5 mt-[-1px] "></i>
                            </button>
                        </div>
                    </footer>
                </form>
            </IonContent >
        </IonPage >
    );
}
