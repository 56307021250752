import { Reorder } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  useGetTasksByWorkcardIdQuery,
  useUpdateTasksOrderMutation,
} from '../../../../../../services/data/workcard.service';
import type { RootState } from '../../../../../../store/store';

import { WorkcardTaskAddItem } from './components/WorkcardTaskAddItem/WorkcardTaskAddItem';
import { WorkcadTaskListItem } from './components/WorkcardTaskListItem/WorkcardTaskListItem';

type ToggleState = (e: React.MouseEvent, value: boolean) => void;
type SelectedTaskId = (e: React.MouseEvent, value: number) => void;

export const WorkcadTaskList: React.FC<{
  selectTaskId: SelectedTaskId;
  toggleState: ToggleState;
  isReorder: boolean;
  showTaskDetail: boolean;
  isWarningModifiedSig?: boolean;
  onTaskAdded? : () => void;
}> = (props) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [items, setItems] = useState<any>([]);

  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const lang = useSelector((state: RootState) => state.settings.lang);

  const { data } = useGetTasksByWorkcardIdQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
    lang: lang,
  });

  const isOnline = useSelector((state: RootState) => state.connectivityStatus.isOnline);

  const [updateTasksOrder] = useUpdateTasksOrderMutation();

  const handleReorder = (items: any) => {
    setItems(items);

    const reorderItems = items.map((item: any, index: number) => {
      return { id: item.id, order: index + 1 };
    });

    updateTasksOrder({ siteId: selectedSiteId!, workcardId: +id, payload: reorderItems });
  };

  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);

  const onTaskAdded = () => {
    if(props.onTaskAdded)
      props.onTaskAdded();
  };

  return (
    <div
      className={`ml-2 lg:mt-2 bb-[30px] lg:pb-0 overflow-y-auto ${props.isWarningModifiedSig ? 'lg:h-[calc(100vh-420px)]' : 'lg:h-[calc(100vh-380px)]'
        } `}
    >
      <Reorder.Group
        layoutScroll
        style={{ overflowY: 'auto' }}
        values={items}
        onReorder={handleReorder}
        role="list"
        className="dark:h-auto   dark:sm:pr-[16px] dark:lg:pr-[30px] overflow-x-hidden"
      >
        {items &&
          items.map((item: any, idx: number) => (
            <WorkcadTaskListItem key={`task-1` + item.id} item={item} index={idx} isReorder={props.isReorder} />
          ))}
        {isOnline && (
          <li className={`border-[2px] !border-workcard-greeting-secondary bg-dark-secondary my-3 ml-2 dark:rounded-xl rounded-xl lg:rounded-none dark:lg:!mx-4 my-4 mx-4 shadow-md lg:shadow-none workcardTaskItem  lg:w-full`}>
            <WorkcardTaskAddItem siteId={selectedSiteId || -1} workcardId={+id} onTaskAdded={onTaskAdded}></WorkcardTaskAddItem>
          </li>
        )}
      </Reorder.Group>

      {items && items.length === 0 && (
        <div className="mb-4 mx-4 block">
          <div className="relative block w-auto border-2 border-gray-300 border-dashed rounded-lg m-4 p-12 pt-20 text-center hover:border-gray-400 ">
            <i className="fa-solid fa-circle-exclamation mx-auto text-5xl text-gray-400"></i>
            <p className="mt-6 block text-base font-medium text-gray-500">
              {t('FEATURES.WORKCARD.WORKCARD_TASKS.WORKCARD_TASK_LIST.NO_TASK_AVAILABLE')}
            </p>
            <p className="mt-2 block text-sm font-medium text-gray-500"> &nbsp; </p>
          </div>
        </div>
      )}
    </div>
  );
};
