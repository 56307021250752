import { useIonModal } from "@ionic/react";
import type { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { t } from "i18next";

import { WorkcardTaskAddModal } from "../WorkcardTaskAddModal/WorkcardTaskAddModal";

export const WorkcardTaskAddItem: React.FC<{ siteId: number, workcardId: number, onTaskAdded?: () => void }> = (props) => {
    const [presentAddTaskDialog, dismissAddTaskDialog] = useIonModal(WorkcardTaskAddModal, {
        siteId: props.siteId,
        workcardId: props.workcardId,
        onDismiss: (data: string, role: string) => dismissAddTaskDialog(data, role),
    });

    const handleAddNewTask = () => {
        presentAddTaskDialog({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
                if (ev.detail.role === 'confirm') {
                    if (props.onTaskAdded)
                        props.onTaskAdded();
                }
            },
            id: 'addNewTaskDialog',
        });
    };

    return (
        <button className="w-full" type="button" onClick={() => handleAddNewTask()}>
            <div className="flex flex-row h-[70px]">
                <div className="relative">
                    <p className=" bg-gradient-to-b from-workcard-greeting-tertiary to-workcard-greeting-primary h-full rounded-l-lg px-2 pt-[25px] text-[40px] font-bold  leading-5 text-dark-primary ">
                        +
                    </p>
                </div>
                <div className="flex flex-row w-full h-full justify-center items-center">
                    <div className="px-2.5 py-auto">
                        <div className={` text-lg  font-bold text-black dark:text-workcard-greeting-secondary`}>
                            <i className="fa-solid fa-plus mr-1"></i>
                            {t('FEATURES.WORKCARD.WORKCARD_GREETING.ADD_NEW_TASK.ADD_BUTTON_LABEL')}
                        </div>
                    </div>
                </div>
            </div>
        </button>
    );
}
