import { Switch } from '@headlessui/react';
import { useIonViewWillEnter } from '@ionic/react';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { LastUpdateInfo } from '../../../../components/LastUpdateInfo/LastUpdateInfo';
import { Loader } from '../../../../components/Loader/Loader';
import { OfflineBanner } from '../../../../components/OfflineBanner/OfflineBanner';
import { WarningMessageModifiedSig } from '../../../../components/WarningMessageModifiedSig/WarningMessageModifiedSig';
import { WorkcardSectionType } from '../../../../enums/workcardSectionType.enum';
import { useAppDispatch, useAppSelector } from '../../../../hooks/hooks';
import type { WorkcardTask, WorkcardTasks as Tasks } from '../../../../models';
import { hasEmployeeLevelSupervisorOnAnyCrew, isUserWorkcardOwner } from '../../../../services/common/profile.utils';
import { classNames } from '../../../../services/common/workcard.utils';
import { useGetMyProfileQuery } from '../../../../services/data/profile.service';
import { useGetTasksByWorkcardIdQuery, useGetWorkcardGreetingsQuery } from '../../../../services/data/workcard.service';
import type { RootState } from '../../../../store/store';
import { setLastUpdateinfo } from '../../../Settings/SettingsSlice';
import { MenuContext } from '../../WorkcardContext';
import { WorkcardHeader } from '../WorkcardHeader/WorkcardHeader';

import { WorkcardChecklistOverview } from './components/WorkcardChecklistOverview/WorkcardChecklistOverview';
import { WorkcadTaskList } from './components/WorkcardTaskList/WorkcardTaskList';
import { WorkcardTaskListLoader } from './components/WorkcardTaskList/WorkcardTaskListLoader';

import './WorkcardGreeting.scss';

type DisablePullToRefresh = (isDisable: boolean) => void;

export const WorkcardGreeting: React.FC<{ onDisablePullToRefresh: DisablePullToRefresh }> = (props) => {
  const mediaQuery = '(max-width: 1023px)';
  const mediaQueryList = window.matchMedia(mediaQuery);
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [isReorder, setIsReorder] = useState<boolean>(false);
  const [showTaskDetail, setTaskDetail] = useState<boolean>(false);
  const [isWarningModifiedSig, setIsWarningModifiedSig] = useState<boolean>(false);
  const [selectedTaskId, setTaskId] = useState<number>(-1);
  const [selectedAllTasksCount, setAllTasksCount] = useState<number>(0);
  const [selectedPercentCompletedTask, setPercentCompletedTask] = useState<number>(0);
  const [selectedCompletedTasksCount, setCompletedTasksCount] = useState<number>(0);
  const [selectedTaskMenu, setSelectedTaskMenu] = useState<string>('Inspection');
  const [lastUpdate, setLastUpdate] = useState('');
  const [enabledReordering, setEnabledReordering] = useState(false);
  const [isUserOwner, setIsUserOwner] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [selectedIsMobileScreen, setIsMobileScreen] = useState(false);

  const mountedRef = useRef(true);

  const { selectedMenu, setselectedMenu } = useContext(MenuContext);
  const selectedSiteId = useSelector((state: RootState) => state.auth.siteId);
  const selectedWorkcard = useAppSelector((state) => state.workcard.selectedWorkcard);
  const lang = useSelector((state: RootState) => state.settings.lang);

  const dispatch = useAppDispatch();

  const { data: myProfileData } = useGetMyProfileQuery();

  const { data, error, isLoading, isFetching, refetch } = useGetTasksByWorkcardIdQuery({
    siteId: selectedSiteId!,
    workcardId: +id,
    lang: lang,
  });

  const { data: dataGreeting, refetch: refetchGreeting } = useGetWorkcardGreetingsQuery({ siteId: +selectedSiteId!, workcardId: +id });

  const mobileScreenChange = (event: any) => {
    setTimeout(() => {
      if (event.matches) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
      }
    }, 300);
  };

  mediaQueryList.addEventListener('change', mobileScreenChange);

  useEffect(() => {
    if (window.matchMedia(mediaQuery).matches) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }

    // cancel subscription to useEffect
    return () => {
      mountedRef.current = false;
      mediaQueryList.removeEventListener('change', mobileScreenChange);
    };
  }, []);

  useIonViewWillEnter(() => {
    refetch();
  });

  const handleSetMenu = (e: React.MouseEvent, value: string) => {
    setSelectedTaskMenu(value);
  };

  const isShowTaskDetail = (e: React.MouseEvent, value: boolean) => {
    setTaskDetail(value);
  };

  const handleSelectTaskId = (e: React.MouseEvent, value: number) => {
    setTaskId(value);
  };

  const setCompletedTasks = (workcardTasks: WorkcardTask[]) => {
    let completedTasks: any = [];

    if (workcardTasks) {
      completedTasks = workcardTasks.filter((task) => task.activityStatus === 'Completed');
    }

    setCompletedTasksCount(completedTasks.length);
  };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    props.onDisablePullToRefresh(enabledReordering);
  }, [enabledReordering]);

  useEffect(() => {
    if (data) {
      setAllTasksCount(data.length);
      setCompletedTasks(data);

      if (data.length > 0) {
        dispatch(setLastUpdateinfo(data[0].lastReadingDate!));
      }
    }

    if (data && data.length > 0) {
      setLastUpdate(data[0].lastReadingDate!);
    }
  }, [data]);

  useEffect(() => {
    if (
      dataGreeting &&
      dataGreeting.postSignatureSectionModifiedOn &&
      ((isSupervisor && !dataGreeting.supervisorSignature) || (isUserOwner && !dataGreeting.workerSignature))
    ) {
      setIsWarningModifiedSig(true);
    } else {
      setIsWarningModifiedSig(false);
    }
  }, [dataGreeting]);

  useEffect(() => {
    const completedTasksRatio = selectedCompletedTasksCount / selectedAllTasksCount;

    setPercentCompletedTask(+completedTasksRatio.toFixed(2) * 100);
  }, [selectedCompletedTasksCount, selectedAllTasksCount]);

  useEffect(() => {
    if (myProfileData) {
      setIsUserOwner(isUserWorkcardOwner(myProfileData?.crews, selectedWorkcard!));
      setIsSupervisor(hasEmployeeLevelSupervisorOnAnyCrew(myProfileData));
    }
  }, [myProfileData]);

  const onTaskAdded = () => {
    refetch();
    refetchGreeting();
  }

  return (
    <>
      {isLoading && <Loader></Loader>}
      {selectedMenu === 'Greeting' && (
        <div className="grid grid-cols-5 gap-0 pt-[7px] lg:pt-0">
          <div className="ml-0 col-span-5">
            {isSupervisor && <WorkcardHeader></WorkcardHeader>}

            {isWarningModifiedSig && (
              <WarningMessageModifiedSig workcardSectionType={WorkcardSectionType.Greeting}></WarningMessageModifiedSig>
            )}

            <div className="w-full border-b border-t border-gray-400 dark:border-none bg-gray-100 dark:bg-dark-tertiary px-4 py-3 lg:px-6 pl-8 relative z-10">
              <div className="-ml-4 -mt-2 flex items-center justify-center lg:flex-nowrap">
                <div className="ml-0 lg:ml-4 mt-2 lg:flex">
                  <h3 className="text-[17px] font-semibold uppercase leading-6 text-gray-900 dark:text-workcard-greeting-secondary">
                    {t('FEATURES.WORKCARD.WORKCARD_GREETING.GREETING')}
                  </h3>
                </div>
              </div>
            </div>

            <div className="lg:border-t-[9px] lg:border-workcard-greeting-primary lg:border-solid lg:bg-workcard-greeting-tertiary lg:pb-4 lg:mt-4 lg:pt-[5px]">
              <div
                className={`col-span-5 lg:col-span-3 relative shadow-lg m-4 rounded-2xl dark:bg-dark-secondary mt-[19px] pt-[43px] pb-[5px] z-10`}
              >
                <div className="">
                  <div className="absolute text-center mx-auto w-[53px] h-[53px] left-0 right-0 rounded-full bg-gradient-to-r from-workcard-greeting-primary to-workcard-greeting-tertiary top-[-28px] lg:top-[-57px]">
                    <span className="icon-greeting text-[34px] mt-[9px] inline-block"></span>
                  </div>

                  {/* {(isLoading || isFetching) && <WorkcardTaskListLoader></WorkcardTaskListLoader>} */}

                  <div className="grid grid-cols-5">
                    <div className="col-span-5 lg:col-span-2">
                      {isSupervisor && (
                        <Switch
                          checked={enabledReordering}
                          onChange={setEnabledReordering}
                          className={classNames(
                            'col-span-5  relative flex h-[30px] w-[52px] flex-shrink-0 cursor-pointer rounded-fullblock m-auto bg-black  rounded-2xl pt-[2px] px-[3px] switch-reordering mt-2 mb-1.5'
                          )}
                        >
                          <span
                            className={classNames(
                              !enabledReordering ? 'translate-x-5' : 'translate-x-0',
                              'pointer-events-none relative inline-block h-[23px] w-[23px] transform rounded-full  shadow ring-0 transition duration-200 ease-in-out bg-gradient-to-r from-workcard-greeting-primary to-workcard-greeting-tertiary'
                            )}
                          ></span>

                          <span className="absolute text-bold top-[6px] right-[60px] text-[11px] text-[#78787a] leading-[11px]">
                            {t('FEATURES.WORKCARD.WORKCARD_GREETING.REORDERING')}
                          </span>
                          <span className="absolute text-bold top-[6px] left-[60px] text-[11px] text-[#78787a]">
                            {t('FEATURES.WORKCARD.WORKCARD_GREETING.FIXED')}
                          </span>
                        </Switch>
                      )}

                      {(isLoading || isFetching) && <WorkcardTaskListLoader></WorkcardTaskListLoader>}

                      {data && !isLoading && !isFetching && (
                        <WorkcadTaskList
                          isWarningModifiedSig={isWarningModifiedSig}
                          isReorder={enabledReordering}
                          showTaskDetail={showTaskDetail}
                          toggleState={(e: React.MouseEvent, value: boolean) => isShowTaskDetail(e, value)}
                          selectTaskId={(e: React.MouseEvent, value: number) => handleSelectTaskId(e, value)}
                          onTaskAdded={onTaskAdded}
                        />
                      )}
                    </div>
                    {!selectedIsMobileScreen && (
                      <div className="hidden lg:block col-span-3 mt-0 ">
                        <div className="ml-0 relative  pb-5">
                          <div
                            className={`overflow-y-auto md:p-[20px] lg:py-0 ${
                              isWarningModifiedSig ? 'lg:h-[calc(100vh-375px)]' : 'lg:h-[calc(100vh-335px)]'
                            }  mx-4 `}
                          >
                            <WorkcardChecklistOverview />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {selectedIsMobileScreen && (
            <div className=" lg:hidden col-span-5 mt-4 md:mb-[100px]">
              <div className="col-span-5 lg:col-span-3 ml-0 relative bg-workcard-greeting-tertiary pt-1.5 border-t-[9px] border-workcard-greeting-primary pb-5">
                <div className="overflow-y-auto md:p-[20px] dark:rounded-xl bg-dark-secondary mx-4 mt-3 ">
                  <WorkcardChecklistOverview />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
